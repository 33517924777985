import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// 기억의 영수증
function Section08_PC() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 08 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section08_ani01 = gsap.timeline();

      section08_ani01
        .from('#section08-pc .content-box01', { delay: .2, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box01 img', { delay: .2, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box04 img', { delay: -.3, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box02 img', { delay: -.3, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box03 img', { delay: -.3, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box06 img', { delay: -.3, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .image-box05 img', { delay: -.3, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .content-box02 span:first-child', { autoAlpha: 0, duration: .7 })
        .from('#section08-pc .content-box02 span:nth-child(2)', { autoAlpha: 0, duration: .7 })
        .from('#section08-pc .content-box02 p', { delay: .2, autoAlpha: 0, duration: .7 })
        .from('#section08-pc .content-box02 strong', { delay: .2, autoAlpha: 0, duration: .7, y: 20 })

      ScrollTrigger.create({
        animation: section08_ani01,
        trigger: '#section08-pc',
        start: 'top 70%',
      });
    })
    return () => context.revert();
  })

  return(
    <div id='section08-pc' className='pc-only'>
      <div className='content-box content-box01'>
        <h3>기억의 영수증</h3>
        <p>
          사진과 연결된 기억을 생생하게 불러오려면, 바로 의미를 담아 자주<br/> 
          꺼내볼 수 있는 형태의 단서가 필요합니다.<br/>
          커피 한 잔을 주문하고 받은 영수증처럼, 특별한 순간이 아닌 일상의<br/>
          소소한 시간에 영수증이 있다면 이런 모습이 아닐까요?<br/>
          조금은 투박하지만, 기억을 불러오기에는 충분하죠.
        </p>
      </div>
      <div className='content-box content-box02'>
        <h3><span>망각,</span> <span>새로운 영수증이 필요할 때</span></h3>
        <p>
          영수증에 담긴 사진은 우리의 기억과 그 모습이 닮아 있습니다.<br/>
          영수증의 사진처럼 우리의 기억과 의미도 시간이 흐르면 점차 희미해집니다.<br/>
          매일 같은 음식만 먹고는 살 수 없듯, 우리에게 의미를 주는 기억도 새로운 것으로 채워져야 하죠.
        </p>
        <strong>지금, 당신이 경험한 시간의 의미를 기억할 수 있게 영수증을 챙겨가세요.</strong>
      </div>
      <div className='image-box image-box01'>
        <img src={process.env.PUBLIC_URL + 'images/08_01.jpg'} alt={'영수증사진기 출력물 01'} />
      </div>
      <div className='image-box image-box02'>
        <img src={process.env.PUBLIC_URL + 'images/08_02.jpg'} alt={'영수증사진기 출력물 02'} />
      </div>
      <div className='image-box image-box03'>
        <img src={process.env.PUBLIC_URL + 'images/08_03.jpg'} alt={'영수증사진기 출력물 03'} />
      </div>
      <div className='image-box image-box04'>
        <img src={process.env.PUBLIC_URL + 'images/08_04.jpg'} alt={'영수증사진기 출력물 04'} />
      </div>
      <div className='image-box image-box05'>
        <img src={process.env.PUBLIC_URL + 'images/08_05.jpg'} alt={'영수증사진기 출력물 05'} />
      </div>
      <div className='image-box image-box06'>
        <img src={process.env.PUBLIC_URL + 'images/08_06.jpg'} alt={'영수증사진기 출력물 06'} />
      </div>
    </div>
  )
}

export default Section08_PC;