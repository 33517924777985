import React, { useState, useEffect, useRef } from 'react';

function ScrollProgress() {
  const [progress, setProgress] = useState(0);
  const progressRef = useRef();

  const handleProgress = () => {
    const ScrollY = document.body.scrollTop || document.documentElement.scrollTop;
    const ScrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    setProgress(( ScrollY / ScrollHeight ) * 100);
  }

  const handlePosition = (e) => {
    const selectedPercent = (e.clientX / progressRef.current.scrollWidth) * 100;
    const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const moveScrollPercent = (windowHeight * selectedPercent) / 100;
    window.scrollTo({ top: moveScrollPercent, behavior: 'smooth' });
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      handleProgress();
    })
  })

  return(
    <div id='progress' className='pc-only' ref={progressRef}>
      <div className='progress_bar' style={{ width: `${progress}%` }} onClick={(e) => handlePosition(e)}></div>
    </div>
  )
}

export default ScrollProgress;