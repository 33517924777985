import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import * as ROUTES from './constants/routes';
import { Main, Dashboard } from './pages';

import './App.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={ROUTES.MAIN} element={<Main />}></Route>
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />}></Route>
      </Routes>
    </Router>
  );
}

export default App;
