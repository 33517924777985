import React, { useState, useLayoutEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Navigation from '../SwiperNavigation';

// MD상품 판매
function Section04() {
  const [slidesBefore, setSlidesBefore] = useState(0);
  const [slidesAfter, setSlidesAfter] = useState(0);

  const handleSlidesMargin = () => {
    if (window.innerWidth <= 1024) {
      // (max-width: 1024px); 16px = 4.4444vw
      setSlidesBefore(window.innerWidth * (4.4444 / 100));
      setSlidesAfter(window.innerWidth * (4.4444 / 100));
    } else {
      // 260px = 13.5417vw
      setSlidesBefore(window.innerWidth * (13.5417 / 100));
      // 60px = 3.1250vw
      setSlidesAfter(window.innerWidth * (3.1250 / 100));
    }
  }

  window.addEventListener('resize', handleSlidesMargin);

  useLayoutEffect(() => {
    handleSlidesMargin();
  })

  const path = '/images/md_product/';
  const slideList = [
    {
      id: '01',
      name_kr: '브레이크프렌즈',
      name_en: '@bwelg_friends',
      image: path + '04_01_x2.png'
    },
    {
      id: '02',
      name_kr: '무룽도원(요즘강아지 덕구)',
      name_en: '@deok.9oo',
      image: path + '04_02_x2.png'
    },
    {
      id: '03',
      name_kr: '몽하우스',
      name_en: '@_monghouse_',
      image: path + '04_03_x2.png'
    },
    {
      id: '04',
      name_kr: '밀앤모이',
      name_en: '@millandmoi',
      image: path + '04_04_x2.png'
    },
    {
      id: '05',
      name_kr: '오식이',
      name_en: '@o_sick_',
      image: path + '04_05_x2.png'
    },
    {
      id: '06',
      name_kr: '눙눙이',
      name_en: '@nungnunge',
      image: path + '04_06_x2.png'
    },
    {
      id: '07',
      name_kr: '애드하프',
      name_en: '@add.half',
      image: path + '04_07_x2.png'
    },
    {
      id: '08',
      name_kr: '빙빙문구',
      name_en: '@bingbing_mungu',
      image: path + '04_08_x2.png'
    },
    {
      id: '09',
      name_kr: '밀앤모이',
      name_en: '@millandmoi',
      image: path + '04_09_x2.png'
    },
    {
      id: '10',
      name_kr: '머픈즈',
      name_en: '@muppenz_studio',
      image: path + '04_10_x2.png'
    },
    {
      id: '11',
      name_kr: '몽댕',
      name_en: '@mongdaeng._.store',
      image: path + '04_11_x2.png'
    },
    {
      id: '12',
      name_kr: '원원',
      name_en: '@quattrocomma',
      image: path + '04_12_x2.png'
    },
    {
      id: '13',
      name_kr: '폴리',
      name_en: '@poly__bear',
      image: path + '04_13_x2.png'
    },
    {
      id: '14',
      name_kr: '미스터도리스튜디오',
      name_en: '@mrdori_studio',
      image: path + '04_14_x2.png'
    },
    {
      id: '15',
      name_kr: '콰트로콤마',
      name_en: '@quattrocomma',
      image: path + '04_15_x2.png'
    },
    {
      id: '16',
      name_kr: '해들해즈',
      name_en: '@ahntail6266',
      image: path + '04_16_x2.png'
    },
    {
      id: '17',
      name_kr: '알알디자인',
      name_en: '@rre_design',
      image: path + '04_17_x2.png'
    },
    {
      id: '18',
      name_kr: '카이프하우스',
      name_en: '@Kaife House.offcial',
      image: path + '04_18_x2.png'
    },
    {
      id: '19',
      name_kr: '아릉낫앵그리',
      name_en: '@arng.not.angry',
      image: path + '04_19_x2.png'
    },
    {
      id: '20',
      name_kr: '삼초밥',
      name_en: '@ha_deong',
      image: path + '04_20_x2.png'
    },
    {
      id: '21',
      name_kr: '요루모루',
      name_en: '@yorumoruu',
      image: path + '04_21_x2.png'
    },
    {
      id: '22',
      name_kr: '밤즈',
      name_en: '@balms.record',
      image: path + '04_22_x2.png'
    },
    {
      id: '23',
      name_kr: '던캔들',
      name_en: '@dawn_candle',
      image: path + '04_23_x2.png'
    },
    {
      id: '24',
      name_kr: '594랜드',
      name_en: '@594_land',
      image: path + '04_24_x2.png'
    },
    {
      id: '25',
      name_kr: '밍코아카이브',
      name_en: '@puppymingko.archive',
      image: path + '04_25_x2.png'
    },
    {
      id: '26',
      name_kr: '유디',
      name_en: '@from.yu_d',
      image: path + '04_26_x2.png'
    },
    {
      id: '27',
      name_kr: '유유클로버',
      name_en: '@uuclover',
      image: path + '04_27_x2.png'
    },
    {
      id: '28',
      name_kr: '보라앤드',
      name_en: '@bora_and',
      image: path + '04_28_x2.png'
    },
    {
      id: '29',
      name_kr: '미요',
      name_en: '@miyo_jeju',
      image: path + '04_29_x2.png'
    },
    {
      id: '30',
      name_kr: '솜뭉이',
      name_en: '@somoong.official',
      image: path + '04_30_x2.png'
    },
    {
      id: '31',
      name_kr: '앵두앤유',
      name_en: '@aengdu.and.you',
      image: path + '04_31_x2.png'
    },
    {
      id: '32',
      name_kr: '오늘의문구',
      name_en: '@todaygoods',
      image: path + '04_32_x2.png'
    },
    {
      id: '33',
      name_kr: '키키베이커리',
      name_en: '@kikiba2kery',
      image: path + '04_33_x2.png'
    },
    {
      id: '34',
      name_kr: '푸르름디자인',
      name_en: '@pureureum_design',
      image: path + '04_34_x2.png'
    },
    {
      id: '35',
      name_kr: '해달상점',
      name_en: '@haedal._.store',
      image: path + '04_35_x2.png'
    },
    {
      id: '36',
      name_kr: '혹시상점',
      name_en: '@hoxy.store_offcial',
      image: path + '04_36_x2.png'
    },
  ]

  return(
    <div id='section04'>
      <div className='particle01 pc-only' style={{ backgroundImage: `url('/images/04_flower_particle.svg')` }}></div>
      <Swiper className='swiper' slidesPerView={'auto'} slidesOffsetBefore={slidesBefore} slidesOffsetAfter={slidesAfter}>
        <section className='content-box'>
          <div className='content'>
            <h2>MD상품 판매</h2>
            <p className='pc-only'>
              브리프컴퍼니와 계약된 MD상품들을 소개합니다.<br/>
              현재 한국에서 인기 있는 브랜드 위주의 MD상품들로 구성하였습니다.<br/>
              한국 작가들의 감성이 담긴 디자인 소품은 일상을 특별하게 만들어 줄 것입니다.<br/>
              예술적인 그림과 귀여운 캐릭터들은 새로운 생활 환경을 창조하며, 감각적인 품격을 더할 것입니다.<br/>
              손에 닿는 브리프의 오브제들, 당신의 일상을 더욱 특별하게 만들어 줄 아이템들이 여기 있습니다.
            </p>
            <p className='mo-only'>
              브리프컴퍼니와 계약된 MD상품들을 소개합니다.<br/>
              현재 한국에서 인기 있는 브랜드 위주의 MD상품들로<br/>
              구성하였습니다. 한국 작가들의 감성이 담긴 디자인 소품은<br/>
              일상을 특별하게 만들어 줄 것입니다.<br/>
              <br/>
              예술적인 그림과 귀여운 캐릭터들은 새로운 생활 환경을<br/>
              창조하며, 감각적인 품격을 더할 것입니다.<br/>
              손에 닿는 브리프의 오브제들, 당신의 일상을 더욱 특별하게<br/>
              만들어 줄 아이템들이 여기 있습니다.
            </p>
          </div>
          <Navigation />
        </section>
        {slideList.map((slide, index) => {
          return(
            <SwiperSlide key={index}>
              <img src={process.env.PUBLIC_URL + `${slide.image}`} alt={`MD상품 판매 이미지${slide.id}`} width={400} height={400} loading='lazy' />
              <div className='swiper-slide-content'>
                <span>{slide.name_kr}</span>
                <span>{slide.name_en}</span>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Section04;