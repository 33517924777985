import React, { useState, useEffect } from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper/modules';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import Navigation from '../SwiperNavigation';

import '@splidejs/react-splide/css';
import "swiper/css/effect-fade";

SwiperCore.use([EffectFade]);

// 브리프 애월 입점 MD
function Section13 () {
  const [mobileSlide, setMobileSlide] = useState(1);

  useEffect(() => {
    const el_m = document.querySelectorAll('.content-container-m.pc-only .m-wrapper');

    el_m.forEach((el) => {
      el.addEventListener('mouseenter', () => {
        el.classList.add('on');
      })
      el.addEventListener('mouseleave', () => {
        el.classList.remove('on');
      })
    })
  })

  const handleCurrentSlide = () => {
    setTimeout(() => {
      const el_current = document.querySelector('.swiper.mo-only .swiper-slide-active');
      const currentSlide = Number(el_current.getAttribute('data-swiper-slide-index')) + 1;

      setMobileSlide(currentSlide);
    }, 0);
  }

  const instagram = 'https://www.instagram.com/';

  const s_path = '/images/md_store/badge/';
  const s_slideList = [
    {
      id: '01',
      name: '594랜드',
      image: s_path + '01.jpg',
      url: instagram + '594_land'
    },
    {
      id: '02',
      name: '눙눙이',
      image: s_path + '02.jpg',
      url: instagram + 'nungnunge'
    },
    {
      id: '03',
      name: '던캔들',
      image: s_path + '03.jpg',
      url: instagram + 'dawn_candle'
    },
    {
      id: '04',
      name: '머픈즈',
      image: s_path + '04.jpg',
      url: instagram + 'muppenz_studio'
    },
    {
      id: '05',
      name: '멜로문',
      image: s_path + '05.jpg',
      url: instagram + 'melo_moon_'
    },
    {
      id: '06',
      name: '몽댕',
      image: s_path + '06.jpg',
      url: instagram + 'mongdaeng_official'
    },
    {
      id: '07',
      name: '몽하우스',
      image: s_path + '07.jpg',
      url: instagram + '_monghouse_'
    },
    {
      id: '08',
      name: '무룩이',
      image: s_path + '08.jpg',
      url: instagram + 'moorugi'
    },
    {
      id: '09',
      name: '미스터도리',
      image: s_path + '09.jpg',
      url: instagram + 'mrdori_studio'
    },
    {
      id: '10',
      name: '미요',
      image: s_path + '10.jpg',
      url: instagram + 'miyo_jeju'
    },
    {
      id: '11',
      name: '민티스트',
      image: s_path + '11.jpg',
      url: instagram + 'mintist__'
    },
    {
      id: '12',
      name: '말앤모이',
      image: s_path + '12.jpg',
      url: instagram + 'millandmoi'
    },
    {
      id: '13',
      name: '밍코아카이브',
      image: s_path + '13.jpg',
      url: instagram + 'puppymingko.archive'
    },
    {
      id: '14',
      name: '보라앤드',
      image: s_path + '14.jpg',
      url: instagram + 'bora_and'
    },
    {
      id: '15',
      name: '빙빙문구',
      image: s_path + '15.jpg',
      url: instagram + 'bingbing_mungu'
    },
    {
      id: '16',
      name: '사그락',
      image: s_path + '16.jpg',
      url: instagram + 'sagrac_official'
    },
    {
      id: '17',
      name: '삼초밥',
      image: s_path + '17.jpg',
      url: instagram + 'ha_deong'
    },
    {
      id: '18',
      name: '솜뭉이',
      image: s_path + '18.jpg',
      url: instagram + 'somoong.official'
    },
    {
      id: '19',
      name: '아릉낫앵그리',
      image: s_path + '19.jpg',
      url: instagram + 'arng.not.angry'
    },
    {
      id: '20',
      name: '아일랜두',
      image: s_path + '20.jpg',
      url: instagram + 'island.ooo'
    },
    {
      id: '21',
      name: '알알이 디자인',
      image: s_path + '21.jpg',
      url: instagram + 'rre_design'
    },
    {
      id: '22',
      name: '애드하프',
      image: s_path + '22.jpg',
      url: instagram + 'add.half'
    },
    {
      id: '23',
      name: '앵두앤유',
      image: s_path + '23.jpg',
      url: instagram + 'aengdu.and.you'
    },
    {
      id: '24',
      name: '오늘의 문구',
      image: s_path + '24.jpg',
      url: instagram + 'todaygoods_'
    },
    {
      id: '25',
      name: '오식이',
      image: s_path + '25.jpg',
      url: instagram + 'o_sick_'
    },
    {
      id: '26',
      name: '요루모루',
      image: s_path + '26.jpg',
      url: instagram + 'yorumoruu'
    },
    {
      id: '27',
      name: '요즘강아지 덕구',
      image: s_path + '27.jpg',
      url: instagram + 'deok.9oo'
    },
    {
      id: '28',
      name: '윈원',
      image: s_path + '28.jpg',
      url: instagram + 'winwon.official'
    },
    {
      id: '29',
      name: '유디',
      image: s_path + '29.jpg',
      url: instagram + 'from.yu_d'
    },
    {
      id: '30',
      name: '콰트로콤마',
      image: s_path + '30.jpg',
      url: instagram + 'quattrocomma'
    },
    {
      id: '31',
      name: '키키베이커리',
      image: s_path + '31.jpg',
      url: instagram + 'kikiba2kery'
    },
    {
      id: '32',
      name: '폴리',
      image: s_path + '32.jpg',
      url: instagram + 'poly__bear'
    },
    {
      id: '33',
      name: '푸르름 디자인',
      image: s_path + '33.jpg',
      url: instagram + 'pureureum_design'
    },
    {
      id: '34',
      name: '해달상점',
      image: s_path + '34.jpg',
      url: instagram + 'haedal._.store'
    },
    {
      id: '35',
      name: '해들해즈',
      image: s_path + '35.jpg',
      url: instagram + 'ahntail6266'
    },
    {
      id: '36',
      name: '혹시상점',
      image: s_path + '36.jpg',
      url: instagram + 'hoxy.store_official'
    },
    {
      id: '37',
      name: 'drawing paper',
      image: s_path + '37.jpg',
      url: instagram + 'drawingpaper.shop'
    },
    {
      id: '38',
      name: 'Kaife House',
      image: s_path + '38.jpg',
      url: instagram + 'kaifehouse.official'
    },
    {
      id: '39',
      name: 'sen-studio',
      image: s_path + '39.jpg',
      url: instagram + 'sen.studio2019'
    }
  ];

  const m_path_pc = '/images/md_store/card-pc/'
  const m_path_mo = '/images/md_store/card-mo/'
  const m_slideList_pc = [
    // GROUP 01
    [
      {
        id: '01',
        name: '594랜드',
        image: m_path_pc + '01.jpg',
        url: instagram + '594_land'
      },
      {
        id: '02',
        name: '멜로문',
        image: m_path_pc + '02.jpg',
        url: instagram + 'melo_moon_'
      }
    ],
    [
      {
        id: '03',
        name: '눙눙이',
        image: m_path_pc + '03.jpg',
        url: instagram + 'nungnunge'
      },
      {
        id: '04',
        name: '몽댕',
        image: m_path_pc + '04.jpg',
        url: instagram + 'mongdaeng_official'
      }
    ],
    [
      {
        id: '05',
        name: '던캔들',
        image: m_path_pc + '05.jpg',
        url: instagram + 'dawn_candle'
      },
      {
        id: '06',
        name: '몽하우스',
        image: m_path_pc + '06.jpg',
        url: instagram + '_monghouse_'
      }
    ],
    [
      {
        id: '07',
        name: '머픈즈',
        image: m_path_pc + '07.jpg',
        url: instagram + 'muppenz_studio'
      },
      {
        id: '08',
        name: '무룩이',
        image: m_path_pc + '08.jpg',
        url: instagram + 'moorugi'
      }
    ],
    [
      {
        id: '09',
        name: '미스터도리',
        image: m_path_pc + '09.jpg',
        url: instagram + 'mrdori_studio'
      },
      {
        id: '10',
        name: '밍코아카이브',
        image: m_path_pc + '10.jpg',
        url: instagram + 'puppymingko.archive'
      }
    ],
    // GROUP 02
    [
      {
        id: '11',
        name: '미요',
        image: m_path_pc + '11.jpg',
        url: instagram + 'miyo_jeju'
      },
      {
        id: '12',
        name: '보라앤드',
        image: m_path_pc + '12.jpg',
        url: instagram + 'bora_and'
      }
    ],
    [
      {
        id: '13',
        name: '민티스트',
        image: m_path_pc + '13.jpg',
        url: instagram + 'mintist__'
      },
      {
        id: '14',
        name: '빙빙문구',
        image: m_path_pc + '14.jpg',
        url: instagram + 'bingbing_mungu'
      }
    ],
    [
      {
        id: '15',
        name: '말앤모이',
        image: m_path_pc + '15.jpg',
        url: instagram + 'millandmoi'
      },
      {
        id: '16',
        name: '사그락',
        image: m_path_pc + '16.jpg',
        url: instagram + 'sagrac_official'
      }
    ],
    [
      {
        id: '17',
        name: '삼초밥',
        image: m_path_pc + '17.jpg',
        url: instagram + 'ha_deong'
      },
      {
        id: '18',
        name: '알알이 디자인',
        image: m_path_pc + '18.jpg',
        url: instagram + 'rre_design'
      }
    ],
    [
      {
        id: '19',
        name: '솜뭉이',
        image: m_path_pc + '19.jpg',
        url: instagram + 'somoong.official'
      },
      {
        id: '20',
        name: '애드하프',
        image: m_path_pc + '20.jpg',
        url: instagram + 'add.half'
      }
    ],
    // GROUP 03
    [
      {
        id: '21',
        name: '아릉낫앵그리',
        image: m_path_pc + '21.jpg',
        url: instagram + 'arng.not.angry'
      },
      {
        id: '22',
        name: '앵두앤유',
        image: m_path_pc + '22.jpg',
        url: instagram + 'aengdu.and.you'
      }
    ],
    [
      {
        id: '23',
        name: '아일랜두',
        image: m_path_pc + '23.jpg',
        url: instagram + 'island.ooo'
      },
      {
        id: '24',
        name: '오늘의 문구',
        image: m_path_pc + '24.jpg',
        url: instagram + 'todaygoods_'
      }
    ],
    [
      {
        id: '25',
        name: '오식이',
        image: m_path_pc + '25.jpg',
        url: instagram + 'o_sick_'
      },
      {
        id: '26',
        name: '유디',
        image: m_path_pc + '26.jpg',
        url: instagram + 'from.yu_d'
      }
    ],
    [
      {
        id: '27',
        name: '요루모루',
        image: m_path_pc + '27.jpg',
        url: instagram + 'yorumoruu'
      },
      {
        id: '28',
        name: '콰트로콤마',
        image: m_path_pc + '28.jpg',
        url: instagram + 'quattrocomma'
      }
    ],
    [
      {
        id: '29',
        name: '요즘강아지 덕구',
        image: m_path_pc + '29.jpg',
        url: instagram + 'deok.9oo'
      },
      {
        id: '30',
        name: '키키베이커리',
        image: m_path_pc + '30.jpg',
        url: instagram + 'kikiba2kery'
      }
    ],
    // GROUP 04
    [
      {
        id: '31',
        name: '윈원',
        image: m_path_pc + '31.jpg',
        url: instagram + 'winwon.official'
      },
      {
        id: '32',
        name: '폴리',
        image: m_path_pc + '32.jpg',
        url: instagram + 'poly__bear'
      }
    ],
    [
      {
        id: '33',
        name: '푸르름 디자인',
        image: m_path_pc + '33.jpg',
        url: instagram + 'pureureum_design'
      },
      {
        id: '34',
        name: 'drawing paper',
        image: m_path_pc + '34.jpg',
        url: instagram + 'drawingpaper.shop'
      }
    ],
    [
      {
        id: '35',
        name: '해달상점',
        image: m_path_pc + '35.jpg',
        url: instagram + 'haedal._.store'
      },
      {
        id: '36',
        name: 'Kaife House',
        image: m_path_pc + '36.jpg',
        url: instagram + 'kaifehouse.official'
      }
    ],
    [
      {
        id: '37',
        name: '해들해즈',
        image: m_path_pc + '37.jpg',
        url: instagram + 'ahntail6266'
      },
      {
        id: '38',
        name: 'sen-studio',
        image: m_path_pc + '38.jpg',
        url: instagram + 'sen.studio2019'
      }
    ],
    [
      {
        id: '39',
        name: '혹시상점',
        image: m_path_pc + '39.jpg',
        url: instagram + 'hoxy.store_official'
      }
    ],
  ];
  const m_slideList_mo = [
    // GROUP 01
    [
      {
        id: '01',
        name: '594랜드',
        image: m_path_mo + '01.jpg',
        url: instagram + '594_land'
      },
      {
        id: '02',
        name: '머픈즈',
        image: m_path_mo + '02.jpg',
        url: instagram + 'muppenz_studio'
      }
    ],
    [
      {
        id: '03',
        name: '눙눙이',
        image: m_path_mo + '03.jpg',
        url: instagram + 'nungnunge'
      },
      {
        id: '04',
        name: '던캔들',
        image: m_path_mo + '04.jpg',
        url: instagram + 'dawn_candle'
      }
    ],
    // GROUP 02
    [
      {
        id: '05',
        name: '멜로문',
        image: m_path_mo + '05.jpg',
        url: instagram + 'melo_moon_'
      },
      {
        id: '06',
        name: '몽하우스',
        image: m_path_mo + '06.jpg',
        url: instagram + '_monghouse_'
      }
    ],
    [
      {
        id: '07',
        name: '몽댕',
        image: m_path_mo + '07.jpg',
        url: instagram + 'mongdaeng_official'
      },
      {
        id: '08',
        name: '무룩이',
        image: m_path_mo + '08.jpg',
        url: instagram + 'moorugi'
      }
    ],
    // GROUP 03
    [
      {
        id: '09',
        name: '미스터도리',
        image: m_path_mo + '09.jpg',
        url: instagram + 'mrdori_studio'
      },
      {
        id: '10',
        name: '민티스트',
        image: m_path_mo + '10.jpg',
        url: instagram + 'mintist__'
      }
    ],
    [
      {
        id: '11',
        name: '미요',
        image: m_path_mo + '11.jpg',
        url: instagram + 'miyo_jeju'
      },
      {
        id: '12',
        name: '말앤모이',
        image: m_path_mo + '12.jpg',
        url: instagram + 'millandmoi'
      }
    ],
    // GROUP 04
    [
      {
        id: '13',
        name: '밍코아카이브',
        image: m_path_mo + '13.jpg',
        url: instagram + 'puppymingko.archive'
      },
      {
        id: '14',
        name: '빙빙문구',
        image: m_path_mo + '14.jpg',
        url: instagram + 'bingbing_mungu'
      }
    ],
    [
      {
        id: '15',
        name: '보라앤드',
        image: m_path_mo + '15.jpg',
        url: instagram + 'bora_and'
      },
      {
        id: '16',
        name: '사그락',
        image: m_path_mo + '16.jpg',
        url: instagram + 'sagrac_official'
      }
    ],
    // GROUP 05
    [
      {
        id: '17',
        name: '삼초밥',
        image: m_path_mo + '17.jpg',
        url: instagram + 'ha_deong'
      },
      {
        id: '18',
        name: '아릉낫앵그리',
        image: m_path_mo + '18.jpg',
        url: instagram + 'arng.not.angry'
      }
    ],
    [
      {
        id: '19',
        name: '솜뭉이',
        image: m_path_mo + '19.jpg',
        url: instagram + 'somoong.official'
      },
      {
        id: '20',
        name: '아일랜두',
        image: m_path_mo + '20.jpg',
        url: instagram + 'island.ooo'
      }
    ],
    // GROUP 06
    [
      {
        id: '21',
        name: '알알이 디자인',
        image: m_path_mo + '21.jpg',
        url: instagram + 'rre_design'
      },
      {
        id: '22',
        name: '앵두앤유',
        image: m_path_mo + '22.jpg',
        url: instagram + 'aengdu.and.you'
      }
    ],
    [
      {
        id: '23',
        name: '애드하프',
        image: m_path_mo + '23.jpg',
        url: instagram + 'add.half'
      },
      {
        id: '24',
        name: '오늘의 문구',
        image: m_path_mo + '24.jpg',
        url: instagram + 'todaygoods_'
      }
    ],
    // GROUP 07
    [
      {
        id: '25',
        name: '오식이',
        image: m_path_mo + '25.jpg',
        url: instagram + 'o_sick_'
      },
      {
        id: '26',
        name: '요즘강아지 덕구',
        image: m_path_mo + '26.jpg',
        url: instagram + 'deok.9oo'
      }
    ],
    [
      {
        id: '27',
        name: '요루모루',
        image: m_path_mo + '27.jpg',
        url: instagram + 'yorumoruu'
      },
      {
        id: '28',
        name: '윈원',
        image: m_path_mo + '28.jpg',
        url: instagram + 'winwon.official'
      }
    ],
    // GROUP 08
    [
      {
        id: '29',
        name: '유디',
        image: m_path_mo + '29.jpg',
        url: instagram + 'from.yu_d'
      },
      {
        id: '30',
        name: '키키베이커리',
        image: m_path_mo + '30.jpg',
        url: instagram + 'kikiba2kery'
      }
    ],
    [
      {
        id: '31',
        name: '콰트로콤마',
        image: m_path_mo + '31.jpg',
        url: instagram + 'quattrocomma'
      },
      {
        id: '32',
        name: '폴리',
        image: m_path_mo + '32.jpg',
        url: instagram + 'poly__bear'
      }
    ],
    // GROUP 09
    [
      {
        id: '33',
        name: '푸르름 디자인',
        image: m_path_mo + '33.jpg',
        url: instagram + 'pureureum_design'
      },
      {
        id: '34',
        name: '해들해즈',
        image: m_path_mo + '34.jpg',
        url: instagram + 'ahntail6266'
      }
    ],
    [
      {
        id: '35',
        name: '해달상점',
        image: m_path_mo + '35.jpg',
        url: instagram + 'haedal._.store'
      },
      {
        id: '36',
        name: '혹시상점',
        image: m_path_mo + '36.jpg',
        url: instagram + 'hoxy.store_official'
      }
    ],
    // GROUP 10
    [
      {
        id: '37',
        name: 'drawing paper',
        image: m_path_mo + '37.jpg',
        url: instagram + 'drawingpaper.shop'
      },
      {
        id: '38',
        name: 'sen-studio',
        image: m_path_mo + '38.jpg',
        url: instagram + 'sen.studio2019'
      }
    ],
    [
      {
        id: '39',
        name: 'Kaife House',
        image: m_path_mo + '39.jpg',
        url: instagram + 'kaifehouse.official'
      }
    ],
  ]

  return(
    <div id='section13'>
      <div className='content-container-s'>
        <div className='content-box'>
          <h2>브리프 애월 입점 MD</h2>
          <p>
            브리프 MD가 여러분들을 위해 픽한 제품!<br/>
            매력적이고 다양한 브랜드를 모두 같이 만나보아요.
          </p>
        </div>
        <Splide options={
          {
            type: 'loop',
            drag: 'free',
            focus  : 'center',
            arrows: false,
            pagination: false,
            perPage: 5,
            autoScroll: {
              pauseOnHover: true,
              pauseOnFocus: false,
              rewind: false,
              speed: 0.5
            }
          }}
          extensions={{ AutoScroll }}
        >
          {s_slideList.map((s) => {
            return(
              <SplideSlide key={s.id}>
                <a href={s.url} target='_blank' rel='noreferrer' className='s-wrapper'>
                  <div className='s-name-box'>
                    <span className='s-name'>{s.name}</span>
                  </div>
                  <img src={process.env.PUBLIC_URL + `${s.image}`} alt={`브리프 애월 입점 MD 이미지S_${s.id}`} className='s-image' />
                </a>
              </SplideSlide>
            )
          })}
        </Splide>
      </div>
      <Swiper className='swiper pc-only' slidesPerView={'auto'} loop={true} modules={[EffectFade]} effect={'fade'} fadeEffect={{ crossFade:true }}>
        {[0, 5, 10, 15].map((startIndex) => (
          <SwiperSlide key={`swiper-slide-${startIndex}`}>
            <div className='content-container-m pc-only'>
              {[0, 1, 2, 3, 4].map((i) => (
                <div className='content-box' key={`content-box${startIndex + i}`}>
                  {m_slideList_pc[startIndex + i].map((m) => (
                    <a href={m.url} target='_blank' rel='noreferrer' className='m-wrapper' key={m.id}>
                      <img src={process.env.PUBLIC_URL + `${m.image}`} alt={`브리프 애월 입점 MD 이미지M_${m.id}`} />
                      <div className='m-name-box'>
                        <span className='m-name'>{m.name}</span>
                      </div>
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </SwiperSlide>
        ))}
        <Navigation />
      </Swiper>
      <Swiper className='swiper mo-only' slidesPerView={'auto'} loop={true} onSlideChange={() => handleCurrentSlide()}>
        {[0, 2, 4, 6, 8, 10, 12, 14, 16, 18].map((startIndex) => (
            <SwiperSlide key={`swiper-slide-${startIndex}`}>
              <div className='content-container-m mo-only'>
                {[0, 1].map((i) => (
                  <div className='content-box' key={`content-box${startIndex + i}`}>
                    {m_slideList_mo[startIndex + i].map((m) => (
                      <a href={m.url} target='_blank' rel='noreferrer' className='m-wrapper' key={m.id}>
                        <img src={process.env.PUBLIC_URL + `${m.image}`} alt={`브리프 애월 입점 MD 이미지M_${m.id}`} />
                        <div className='m-name-box'>
                          <span className='m-name'>{m.name}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
          <div className='swiper-progress-wrapper'>
            <div className='swiper-progress-bar' style={{ width: `${mobileSlide * 10}%`}}></div>
          </div>
      </Swiper>
    </div>
  )
}

export default Section13;