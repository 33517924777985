import React, { useState, useRef } from 'react';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import emailjs from '@emailjs/browser';

import 'react-toastify/dist/ReactToastify.css';

// Contact Us
function Section14() {
  const [currentOption, setCurrentOption] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const alertRequire = (alertMessage) => toast.error(alertMessage, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: '',
    theme: 'light',
    transition: Zoom
  });

  const alertNotify = (alertMessage) => toast.warn(alertMessage, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: '',
    theme: 'light',
    transition: Zoom
  });

  const alertSuccess = (alertMessage) => toast.success(alertMessage, {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: '',
    theme: 'light',
    transition: Zoom
  })

  const optionRef = useRef();
  const checkRef = useRef();
  const formRef = useRef();
  
  const handleDropdown = () => {
    setIsActive(isActive => !isActive);
  }

  const handleOption = (event) => {
    const option_txt = event.target.textContent;
    setCurrentOption(option_txt);
    setIsSelected(true);
  }

  const checkValidate = (event) => {
    event.preventDefault();

    console.log(formRef.current)

    var form_data = {
      name: null,
      mail: null,
      phone: null,
      option: null,
      content: ''
    }

    // CHECK NAME (REQUIRED)
    const name_input = document.querySelector('input#name');
    const name_data = name_input.value;
    if (name_data === null || name_data === '' || typeof name_data === 'undefined') {
      alertRequire('성함(상호명)은 필수입력 영역입니다.');
      return;
    } else {
      form_data.name = name_data;
    }

    // CHECK MAIL (REQUIRED)
    // 이메일 양식; 영문숫자@영문.영문(2-3자)
    const mail_input = document.querySelector('input#mail');
    const mail_data = mail_input.value;
    // 이메일 양식에 부합하는지 확인하는 정규식
    const mail_validation = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}'); /* eslint-disable-line */
    if (mail_data === null || mail_data === '' || typeof mail_data === 'undefined') {
      alertRequire('이메일은 필수입력 영역입니다.');
      return;
      // 이메일의 양식에 일치하지 않으면 Notify 노출
    } else if (!mail_validation.test(mail_data)) {
      alertNotify('이메일을 다시 한 번 확인해주세요.');
      return;
    } else {
      form_data.mail = mail_data;
    }

    // CHECK PHONE (REQUIRED)
    // 연락처 양식; 한글/영문과 같은 문자가 포함되지 않으며 8자 이상
    const phone_input = document.querySelector('input#phone');
    const phone_data = phone_input.value;
    // 한글, 영문과 같은 문자가 포함되어 있는지 확인하는 정규식
    const phone_validation = new RegExp('[ㄱ-ㅎ가-힣a-zA-Z]');
    if (phone_data === null || phone_data === '' || typeof phone_data === 'undefined') {
      alertRequire('연락처는 필수입력 영역입니다.');
      return;
      // 연락처의 양식에 일치하지 않으면 Notify 노출
    } else if (phone_data.length < 8 || phone_validation.test(phone_data)) {
      alertNotify('연락처를 다시 한 번 확인해주세요.')
      return;
    } else {
      form_data.phone = phone_data.replace(/-/g, '');
    }

    // CHECK OPTION (REQUIRED)
    const option_input = document.querySelector('input.option-current');
    const option_data = option_input.value;
    if (option_data === '문의하실 옵션을 선택해 주세요. (필수)' || !optionRef.current.classList.contains('selected')) {
      alertRequire('문의하실 옵션을 필수로 선택해 주세요.')
      return;
    } else {
      form_data.option = option_data;
    }

    // CHECK CONTENT
    const content_input = document.querySelector('textarea#content');
    const content_data = content_input.value;
    form_data.content = content_data;

    // CHECK PRIVACY (REQUIRED)
    if (!checkRef.current.checked) {
      alertRequire('개인정보처리방침에 동의해주세요.')
      return;
    }

    if (form_data.name && form_data.mail && form_data.phone && form_data.option && checkRef.current.checked) {
      console.log(form_data)

      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, formRef.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          alertSuccess('메일이 정상적으로 전송되었습니다.')
        }, (error) => {
          console.log(error.text);
          alertRequire('메일을 전송하는데 실패했습니다.');
        })
    }
  }

  return(
    <>
      <div id='section14'>
        <div className='map-box'>
          <img src={process.env.PUBLIC_URL + '/images/14_map.svg'} className='footer_logo' alt={'브리프 로고'} />
        </div>
        <form ref={formRef} className='form' onSubmit={(event) => checkValidate(event)}>
          <div className='title-box'>
            <h2>Contact us</h2>
            <span>브리프 애월 비즈니스 문의</span>
          </div>
          <div className='name-box'>
            <strong className='label'>성함(상호명) *</strong>
            <input type='text' placeholder='성함(상호명)을 입력해 주세요.' id='name' autoComplete='on' name='user_name'></input>
          </div>
          <div className='mail-box'>
            <strong className='label'>이메일 *</strong>
            <input type='email' placeholder='이메일을 입력해 주세요.' id='mail' name='user_mail'></input>
          </div>
          <div className='phone-box'>
            <strong className='label'>연락처 *</strong>
            <input type='tel' placeholder='연락처를 입력해 주세요.' id='phone' maxLength={13} autoComplete='on' name='user_phone'></input>
          </div>
          <div className={`option-box ${isActive ? 'on' : ''}`} onClick={() => handleDropdown()}>
            <input ref={optionRef} className={`option-current ${isSelected ? 'selected' : ''}`} value={currentOption} placeholder='문의하실 옵션을 선택해 주세요. (필수)' name='user_option' readOnly></input>
            <ul className={`option-list ${isActive ? 'on' : ''}`}>
              <li className='option-item' onClick={(event) => handleOption(event)}>비즈니스 문의</li>
              <li className='option-item' onClick={(event) => handleOption(event)}>가맹점 문의</li>
              <li className='option-item' onClick={(event) => handleOption(event)}>MD상품 문의</li>
              <li className='option-item' onClick={(event) => handleOption(event)}>MD입점 문의</li>
            </ul>
          </div>
          <div className='content-box'>
            <textarea placeholder='문의하실 내용을 입력해 주세요.' id='content' name='user_content'></textarea>
          </div>
          <div className='privacy-container'>
            <div className='privacy-box'>
              <input ref={checkRef} type='checkbox' id='privacy' />
              <label htmlFor='privacy'></label>
              <span><strong>개인정보처리방침</strong>에 동의합니다. <strong>(필수)</strong></span>
            </div>
            <a href='#section14'>개인정보처리방침</a>
          </div>
          <button type='submit' className='sendBtn'>문의하기</button>
        </form>
      </div>
      <ToastContainer position="bottom-center" autoClose={3000} limit={2} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' transition={Zoom} toastClassName={'notification'} style={{ width: '100%', minWidth: '375px', maxWidth: '430px' }} />
    </>
  )
}

export default Section14;