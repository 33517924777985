import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// BRIEF CREW (2D)
function Section11_PC() {
  const [update, setUpdate] = useState(0);

  gsap.registerPlugin(ScrollTrigger);

  // SECTION 11 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section11_ani_pc = gsap.timeline();

      section11_ani_pc
        .from('#section11-pc .story-image', { autoAlpha: 0, duration: .6, y:200, ease: 'back.out(3)' })

      ScrollTrigger.create({
        animation: section11_ani_pc,
        trigger: '#section11-pc',
        start: 'top top',
      })

      ScrollTrigger.create({
        trigger: '#section11-pc .group-image',
        start: 'top top',
        pin: true,
        pinSpacing: false,
      });
    })
    return () => context.revert();
  })

  window.addEventListener('resize', () => {
    setUpdate(window.innerWidth);
  })

  // INTRO FUNCTION
  useEffect(() => {
    const boxes = document.querySelectorAll('.intro-char-images');
    boxes.forEach((box) => {
      const images = box.querySelectorAll('.intro-char-image');   
      const basic = images[0];
      const hover = images[1];
      const name = basic.classList[1].split('-')[0];
      const titleContainer = document.querySelector('.intro-title-container');
      const defaultTitle = document.querySelector('.intro-title-default');
      
      images.forEach((image) => {
        image.addEventListener('mouseenter', () => {
          if (basic.classList.contains('on')) {
            // INTRO CHARACTER CHANGE (01 > 02)
            basic.classList.replace('on', 'off');
            hover.classList.replace('off', 'on');
            // CONTAINER OPACITY (0 > 1)
            titleContainer.classList.add('on');
            titleContainer.classList.remove('off');
            // CHANGE INTRO TITLE (DEFAULT > CHANGABLE)
            document.querySelector(`.intro-title-item.${name}`).classList.remove('displaynone');
            defaultTitle.classList.add('displaynone');
          }
        })
        image.addEventListener('mouseleave', () => {
          if (basic.classList.contains('off')) {
            // INTRO CHARACTER CHANGE (02 > 01)
            basic.classList.replace('off', 'on');
            hover.classList.replace('on', 'off');
            // CONTAINER OPACITY (1 > 0)
            titleContainer.classList.remove('on');
            titleContainer.classList.add('off');
            // CHANGE INTRO TITLE (CHANGABLE > DEFAULT)
            document.querySelector(`.intro-title-item.${name}`).classList.add('displaynone');
            defaultTitle.classList.remove('displaynone');
            setTimeout(() => {
              titleContainer.classList.remove('off');
            }, 100);
          }
        })
      })
    })
  }, [update])

  const charList = [
    {
      'name_en': 'tanky',
      'name_ko': '탱키',
      'tags': ['친근한', '핵인싸', '장난꾸러기', '나잡아봐라'],
      'content': [
        '안녕하세요! 나는 탱키예요.',
        '다른 친구들과는 다르게 나는 라벨스티커를 출력한답니다! 나를 찍을 땐 조심해용.',
        '나는 라벨스티커가 출력될 때 호다닥 도망가는 걸 좋아하거든요.',
        '스티커 사진이 갖고 싶어용? 그렇다면? 나 잡아봐라~!'
      ]
    },
    {
      'name_en': 'awory',
      'name_ko': '애워리',
      'tags': ['유쾌한', '다정한', '능글맞은', '윙크달인'],
      'content': [
        '안녕하세요 ;-) 저는 애워리라고 해요. 벽 뒤에 숨은 저를 찾아보세요!',
        '여러분을 향해 몰래 윙크하는 저를 발견하신다면 소중한 추억을 영수증사진에 담아드릴게요.',
        '여러분은 여기 브리프 애월에서 어떤 감정을 오래오래 기억하고 싶으신가요?'
      ]
    },
    {
      'name_en': 'lite',
      'name_ko': '리트',
      'tags': ['귀여운', '애교쟁이', '사람좋아', '나랑놀아'],
      'content': [
        '안녕? 난 리트야. 나는 사람을 너무너무 좋아해!',
        '그러니까 브리프 애월에 오면 꼭 나를 만나고 가야 해?',
        '나랑 같이 놀아주면 나는 그 대신에 즐거운 추억을 선물해 줄게. 작다고 무시하면 안돼! 비록 작지만,',
        '다른 크루원들처럼 소중하고 오래오래 남는 기억을 담을 수 있거든!'
      ]
    },
    {
      'name_en': 'aiden',
      'name_ko': '에이든',
      'tags': ['샤이한', '여행객', '자유영혼', '내향관종'],
      'content': [
        '안녕하세요! 나는 에이든이에요.',
        '한가지 미리 소개할게요, 나는 부끄럼이 많아요.',
        '그렇지만 내게 와서 사진을 찍는다면 한쪽 눈을 감고 성심성의껏 찍어드릴게요.',
        '사진을 가장 잘 찍는 방법은 애정을 듬뿍 담아 찍는 거라는 것, 알고 계시죠?'
      ]
    }
  ]

  return(
    <div id='section11-pc' className='pc-only'>
      <strong className='title'>HELLO!<br/>BRIEF CREW</strong>
      <div className='story-container'>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/story/group.svg'} className='story-image' alt={'브리프 크루 스토리 이미지'} />
        <div className='story-box'>
          <strong className='story-title'>BACKGROUND STORY</strong>
          <p className='story-content'>
            브리프에는 네 명의 친구들이 있어요.<br/>
            그 친구들이 궁금하다면, 불이 꺼지고 난 뒤에 영수증사진기®를 지켜보면 돼요.<br/>
            그럼 네 개의 그림자가 사람들의 눈을 피해 움직이는 것을 알아챌 수 있을 거예요.<br/>
            우리는 무리지어 다니는 이 친구들을 브리프 크루라고 부르기로 했답니다.<br/>
            브리프 크루는 여기저기 여행을 다니며 느낀 감정들을 바탕으로,<br/>
            영수증사진기®로 돌아와 사진을 찍어주며 사람들의 하루를 기록해줘요.<br/>
            이 친구들은 주로 서울 연희동과 제주 애월에서 포착된다고 하는데…<br/>
            혹시, 어떤 친구들인지 궁금하지 않나요?<br/>
            앞으로 브리프 크루의 여정을 함께 지켜봐 주세요!
          </p>
        </div>
      </div>
      <div className='intro-container'>
        <ul className='intro-title-container'>
          <li className='intro-title-default'>
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/brief_crew.svg'} className='intro-brief-crew' alt={'브리프 크루'} />
            브리프 크루를 소개합니다
          </li>
          {charList.map((char) => {
            return(
              <li className={`intro-title-item ${char.name_en} displaynone`} key={Math.random()}>
                <strong className='intro-title-name'>{char.name_ko}</strong>
                <ul className='intro-title-tags'>
                  {char.tags.map((tag) => { return <li className='intro-title-tag' key={Math.random()}>#{tag}</li>; })}
                </ul>
                <p className='intro-title-content'>
                  {char.content.map((line) => { return <span key={Math.random()}>{line}<br/></span>; })}
                </p>
              </li>
            )
          })}
        </ul>
        <ul className='intro-char-container'>
          <li className='intro-char-box'>
            <div className='intro-char-images box01'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/tanky_default.svg'} className='intro-char-image tanky-01 on' alt={'탱키 소개 이미지01'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/tanky_active.svg'} className='intro-char-image tanky-02 off' alt={'탱키 소개 이미지02'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/tanky_shadow.svg'} className='intro-char-image tanky-shadow' alt={'탱키 소개 그림자'} />
            </div>
            <span className='intro-char-name'>TANKY</span>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-images box02'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/awory_default.svg'} className='intro-char-image awory-01 on' alt={'애워리 소개 이미지01'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/awory_active.svg'} className='intro-char-image awory-02 off' alt={'애워리 소개 이미지02'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/awory_shadow.svg'} className='intro-char-image awory-shadow' alt={'애워리 소개 그림자'} />
            </div>
            <span className='intro-char-name'>AWORY</span>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-images box03'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/lite_default.svg'} className='intro-char-image lite-01 on' alt={'리트 소개 이미지01'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/lite_active.svg'} className='intro-char-image lite-02 off' alt={'리트 소개 이미지02'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/lite_shadow.svg'} className='intro-char-image lite-shadow' alt={'리트 소개 그림자'} />
            </div>
            <span className='intro-char-name'>LITE</span>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-images box04'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/aiden_default.svg'} className='intro-char-image aiden-01 on' alt={'에이든 소개 이미지01'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/aiden_active.svg'} className='intro-char-image aiden-02 off' alt={'에이든 소개 이미지02'} />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/aiden_shadow.svg'} className='intro-char-image aiden-shadow' alt={'에이든 소개 그림자'} />
            </div>
            <span className='intro-char-name'>AIDEN</span>
          </li>
        </ul>
      </div>
      <div className='group-container' data={update}>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/group.svg'} className='group-image' alt={'브리프 크루 단체 이미지'} />
      </div>
      <div className='info-container'>
        <div className='info-box'>
          <div className='info-image-box'>
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/tanky_costume.svg'} className='info-image tanky-01' alt={'탱키 상세 이미지01'} />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/tanky_origin.svg'} className='info-image tanky-02' alt={'탱키 상세 이미지02'} />
          </div>
          <div className='info-content-box'>
            <div className='info-thumb-box'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/tanky_thumb.svg'} className='info-thumb tanky' alt={'탱키 상세 아이콘'} />
            </div>
            <p className='info-name'>
              <span className='name-en'>TANKY</span>
              <span className='name-ko'>탱키</span>
            </p>
            <p className='info-detail'>
              <span>취미 : 남들 시선을 피해 은근슬쩍 춤추기</span>
              <span>특기 : 라벨스티커가 출력될 때 도망가기</span>
            </p>
          </div>
        </div>
        <div className='info-box'>
          <div className='info-image-box'>
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/awory_origin.svg'} className='info-image awory-01' alt={'애워리 상세 이미지01'} />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/awory_costume.svg'} className='info-image awory-02' alt={'애워리 상세 이미지02'} />
          </div>
          <div className='info-content-box'>
            <div className='info-thumb-box'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/awory_thumb.svg'} className='info-thumb awory' alt={'애워리 상세 아이콘'} />
            </div>
            <p className='info-name'>
              <span className='name-en'>AWORY</span>
              <span className='name-ko'>애워리</span>
            </p>
            <p className='info-detail'>
              <span>취미 : 벽 뒤에 스르륵 숨기</span>
              <span>특기 : 치명적인 윙크 발사하기</span>
            </p>
          </div>
        </div>
        <div className='info-box'>
          <div className='info-image-box'>
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/lite_costume.svg'} className='info-image lite-01' alt={'리트 상세 이미지01'} />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/lite_origin.svg'} className='info-image lite-02' alt={'리트 상세 이미지02'} />
          </div>
          <div className='info-content-box'>
            <div className='info-thumb-box'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/lite_thumb.svg'} className='info-thumb lite' alt={'리트 상세 아이콘'} />
            </div>
            <p className='info-name'>
              <span className='name-en'>LITE</span>
              <span className='name-ko'>리트</span>
            </p>
            <p className='info-detail'>
              <span>취미 : 다른 크루원 행동 따라하기</span>
              <span>특기 : 사람들을 졸졸 쫓아다니기</span>
            </p>
          </div>
        </div>
        <div className='info-box'>
          <div className='info-image-box'>
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/aiden_costume.svg'} className='info-image aiden-01' alt={'에이든 상세 이미지01'} />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/aiden_origin.svg'} className='info-image aiden-02' alt={'에이든 상세 이미지02'} />
          </div>
          <div className='info-content-box'>
            <div className='info-thumb-box'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/info/aiden_thumb.svg'} className='info-thumb aiden' alt={'에이든 상세 아이콘'} />
            </div>
            <p className='info-name'>
              <span className='name-en'>AIDEN</span>
              <span className='name-ko'>에이든</span>
            </p>
            <p className='info-detail'>
              <span>취미 : 혼자서 애월을 훌쩍 여행하기</span>
              <span>특기 : 태양을 피하는 방법 연구하기</span>
            </p>
          </div>
        </div>
      </div>
      <div className='sticker-container'>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/brief_crew.svg'} className='sticker-brief-crew' alt={'브리프 크루'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/lite.svg'} className='sticker-lite' alt={'리트'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/orange.svg'} className='sticker-orange' alt={'감귤'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/awory.svg'} className='sticker-awory' alt={'애워리'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/aiden.svg'} className='sticker-aiden' alt={'에이든'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/dolhareubang.png'} className='sticker-dolhareubang' alt={'돌하르방'} />
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/sticker/tanky.svg'} className='sticker-tanky' alt={'탱키'} />
      </div>
    </div>
  )
}

export default Section11_PC;