import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// 기억의 단서, 기억과 연결되어 있는, 사진의 가치
function Section07() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 06 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section07_ani = gsap.timeline();

      section07_ani
        .from('#section07 .content01-item01 h3', { autoAlpha: 0, duration: .4, })
        .from('#section07 .content01-item01 p', { autoAlpha: 0, duration: .4, x:-20, delay: -.1, })
        .from('#section07 .content01-item02 h3', { autoAlpha: 0, duration: .4, delay: .5, })
        .from('#section07 .content01-item02 p', { autoAlpha: 0, duration: .4, x:-20, delay: -.1, })
        .from('#section07 .content01-item03 h3', { autoAlpha: 0, duration: .4, delay: .5, })
        .from('#section07 .content01-item03 p', { autoAlpha: 0, duration: .4, x:-20, delay: -.1, })

      ScrollTrigger.create({
        animation: section07_ani,
        trigger: '#section07',
        start: 'top 70%',
      })
    })
    return () => context.revert();
  })

  return(
    <div id='section07'>
      <div className='contents'>
        <div className='content01'>
          <section className='content01-item01'>
            <h3>기억의 단서, 사진</h3>
            <p>
            우리는 간직하고 싶은 순간을 기억해 내기 위해 단서를 남깁니다.<br/>
            감동적인 전시나 영화의 티켓, 여유를 만끽했던 커피 한 잔의 영수증,<br/>
            멋진 배경과 소중한 사람이 함께한 사진까지. 작은 단서 하나면 장소,<br/>
            날씨, 냄새, 함께했던 누군가, 그때 느꼈던 감정이 되살아납니다.
            </p>
          </section>
          <section className='content01-item02'>
            <h3>기억과 연결되어 있는</h3>
            <p>
              사진은 보다 생생하게 기억을 소환합니다. 우리가 찍은 사진들은<br/>
              모두 기억과 연결되어 있지만, 새로운 기억이 만들어질 때마다<br/>
              오래된 과거는 차츰 잊힙니다. 그 때문에 사람들은 표정을 짓고,<br/>
              시간과 메시지를 쓰고, 오래 기억하기 위해 사진에 의미를 담습니다.
            </p>
          </section>
          <section className='content01-item03'>
            <h3>사진의 가치</h3>
            <p className='pc-only'>
              우리가 일상에서 찍는 사진들은 다시 들여다보며 그때의 행복을 현재로<br/>
              불러올 때 더욱 가치 있습니다. 디지털 세상에서는 이런 일이 너무<br/>
              쉬워졌지만, 사진을 찍는 데 집중한 나머지, 꺼내보는 일은 종종 잊힙니다.<br/>
              수백 수천의 스마트폰 속 사진들을 sns에 공유하고 나면<br/>
              볼 일이 별로 없는 것처럼요.
            </p>
            <p className='mo-only'>
              우리가 일상에서 찍는 사진들은 다시 들여다보며 그때의 행복을<br/>
              현재로 불러올 때 더욱 가치 있습니다. 디지털 세상에서는 이런 일이<br/>
              너무 쉬워졌지만, 사진을 찍는 데 집중한 나머지, 꺼내보는 일은 종종<br/>
              잊힙니다. 수백 수천의 스마트폰 속 사진들을 sns에 공유하고 나면<br/>
              볼 일이 별로 없는 것처럼요.
            </p>
          </section>
        </div>
        <div className='content02'>
          <img className='content02-image' src={process.env.PUBLIC_URL + '/images/07_briefcafe.jpg'} alt='브리프 카페 이미지' />
        </div>
      </div>
    </div>
  )
}

export default Section07;