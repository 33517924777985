import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/react';

import Navigation from '../SwiperNavigation';

// 브리프 애월 01
function Section03() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 03 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section03_ani01 = gsap.timeline();
      const section03_ani02 = gsap.timeline();

      section03_ani01
        .from('#section03 .particle01', { autoAlpha: 0, duration: .5, scale: .2, ease: 'back.out(3)' })
        .from('#section03 .particle02', { autoAlpha: 0, duration: .5, scale: .2, ease: 'back.out(3)' })

      section03_ani02
        .from('#section03 .particle03', { delay: 1, autoAlpha: 0, duration: .5, scale: .2, ease: 'back.out(3)' })

      ScrollTrigger.create({
        animation: section03_ani01,
        trigger: '#section03',
        start: 'top 90%',
      });

      ScrollTrigger.create({
        animation: section03_ani02,
        trigger: '#section03',
        start: 'top 70%',
      });
    })
    return () => context.revert();
  })

  const path = '/images/brief_aewol/';
  const slideList = [];

  for (let i = 1; i <= 10; i++) {
    const current = i.toString().padStart(2, '0');
    const image_data = {
      id: current,
      image: path + `${current}.jpg`,
      webp: path + `${current}.webp`,
      mo: path + `${current}_mo.webp`
    }
    slideList.push(image_data);
  }

  return(
    <div id='section03'>
      <div className='particle01'></div>
      <div className='particle02'></div>
      <div className='particle03'></div>
      <Swiper className='swiper' cssMode={true} slidesPerView={'auto'}>
        {slideList.map((slide) => {
          return(
            <SwiperSlide key={slide.id}>
              <picture>
                <source media='(max-width: 768px)' srcSet={process.env.PUBLIC_URL + `${slide.mo}`} type='image/webp' />
                <source srcSet={process.env.PUBLIC_URL + `${slide.webp}`} type='image/webp' />
                <img src={process.env.PUBLIC_URL + `${slide.image}`} alt={`브리프 애월 이미지${slide.id}`} />
              </picture>
            </SwiperSlide>
          )
        })}
        <section className='contents'>
          <div className='content01'>
            <h2>브리프 애월</h2>
            <p className='pc-only'>
              브리프 애월은 카페, 편집숍, 스튜디오가 융합된 복합문화공간입니다.<br/>
              브리프에서 애월만의 다양한 시그니처 음료 및 디저트를 맛볼 수 있습니다.
            </p>
            <p className='mo-only'>
              브리프 애월은<br/>
              카페, 편집숍, 스튜디오가 융합된 복합문화공간입니다.<br/>
              브리프에서 애월만의 다양한 시그니처 음료 및<br/>
              디저트를 맛볼 수 있습니다.
            </p>
          </div>
          <div className='content02'>
            <Navigation />
          </div>
        </section>
      </Swiper>
    </div>
  )
} 

export default Section03;