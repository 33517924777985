import axios from "axios";
import { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import '../styles/Dashboard.css';

import { BarController, BarElement, CategoryScale, Chart as ChartJS, Legend, LineController, LineElement, LinearScale, PointElement, Tooltip, defaults, } from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register( LinearScale, CategoryScale, BarElement, PointElement, LineElement, Legend, Tooltip, LineController, BarController );
defaults.font.family = 'Pretendard';
defaults.font.size = '16px';

const Dashboard = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [reports ,setReports] = useState([]);
    const { current: render } = useRef(['renderOnce']);

    useEffect(() => {
        axios.post('https://accounts.google.com/o/oauth2/token',
            {
                client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
                refresh_token: process.env.REACT_APP_OAUTH_REFRESH_TOKEN,
                grant_type: "refresh_token"
            }
        )
        .then((response) => {
            axios.post(`https://analyticsdata.googleapis.com/v1beta/properties/${process.env.REACT_APP_GA4_PROPERTY_ID}:runReport`,
                {
                    dimensions: [{ name: "date" }],
                    metrics: [{ name: "activeUsers" }, { name: "screenPageViews" }],
                    dateRanges: [{ startDate: "2024-01-01", endDate: "today" }],
                    orderBys: [{
                        dimension: {
                            orderType: "ALPHANUMERIC",
                            dimensionName: "date"
                        }
                    }],
                    keepEmptyRows: true
                },
                {
                    headers: {
                        'Authorization': `Bearer ${response.data.access_token}`
                    }
                }
            )
            .then((response) => {
                setReports([...response.data.rows]);
                setIsLoaded(true);
            })
            .catch((error) => {
                console.log('[REPORT ERROR] ', error);
            })
        })
        .catch((error) => {
            console.log('[TOKEN ERROR] ', error);
        })

    }, [render])

    let labels = [],
        userCounts = [],
        viewCounts = [];

    reports.forEach((report) => {
        const date = report.dimensionValues[0].value;
        labels.push(date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6));
        
        const userCount = report.metricValues[0].value;
        userCounts.push(Number(userCount));

        const viewCount = report.metricValues[1].value;
        viewCounts.push(Number(viewCount));
    })

    if (labels.length > 7) {
        labels.splice(0, labels.length - 7);
        userCounts.splice(0, userCounts.length - 7);
        viewCounts.splice(0, viewCounts.length - 7);
    }

    const data = {
        labels,
        datasets: [
            {
                type: 'line',
                label: '사용자 수',
                backgroundColor: 'rgb(32,32,32)',
                borderColor: 'rgb(32,32,32)',
                borderWidth: 3,
                fill: false,
                data: labels.map((e, i) => userCounts[i]),
            },
            {
                type: 'bar',
                label: '페이지 조회수',
                backgroundColor: 'rgb(238,120,0)',
                data: labels.map((e, i) => viewCounts[i]),
            },
        ],
    }

    return(
        <div id='dashboard'>
            <Header />
            { isLoaded ?
            <div className='dashboard-container'>
                <div className='chart-container'>
                    <Chart type='bar' data={data} />
                </div>
                <div className='table-container'>
                    <table>
                        <caption>사용자 전체 테이블</caption>
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>사용자</th>
                                <th>조회수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {[...reports].reverse().map((report) => {
                                const date = report.dimensionValues[0].value;
                                const userCount = report.metricValues[0].value;
                                const viewCount = report.metricValues[1].value;

                                return (
                                    <tr key={date}>
                                        <td>{date.substring(0, 4) + '-' + date.substring(4, 6) + '-' + date.substring(6)}</td>
                                        <td>{userCount}</td>
                                        <td>{viewCount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div style={{ display: 'flex', position: 'fixed', top: '0', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', minHeight: '700px' }}>
                <img src={process.env.PUBLIC_URL + 'images/spinner.gif'} alt='로딩 스피너 이미지' />
            </div>
            }
        </div>
    )
}

export default Dashboard;