import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// 그 때의 우리는______
function Section09() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 09 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section09_ani01_pc = gsap.timeline();

      section09_ani01_pc
        .from('#section09-pc .content-box h3 span:first-child', { delay: .2, autoAlpha: 0, duration: .5, y: 20 })
        .from('#section09-pc .content-box h3 span:last-child', { delay: .2, autoAlpha: 0, duration: .5 })
        .from('#section09-pc .content-box p', { delay: .2, autoAlpha: 0, duration: .5 })

      ScrollTrigger.create({
        animation: section09_ani01_pc,
        trigger: '#section09-pc',
        start: 'top 35%',
      });
    })

    return () => context.revert();
  })

  return(
    <>
      {/* SECTION 09 DESKTOP */}
      <div id='section09-pc' className='pc-only'>
        <section className='content-box'>
          <h3>
            <span>지금,&nbsp;</span>
            <span>
              우리가 머물고 있는<br/>
              자리를 추억 한 켠에<br/>
              새겨보세요
            </span>
          </h3>
          <p className='pc-only'>
            새로운 기억이 생기면서 오래된 기억은 빛이 바랜 것처럼 흐릿해집니다.<br/>
            그러나 오래된 영수증 사진을 다시 확인하면 추억이 다시 회상되며 선명해지지만<br/>
            그때 그 감정과는 색다른 의미로 다가옵니다.<br/>
            영수증사진기®로 지금 우리가 머물고 있는 자리를 추억 한 켠에 새겨보세요.
          </p>
          <p className='mo-only'>
            새로운 기억이 생기면서 오래된 기억은 빛이 바랜 것처럼 흐릿해집니다.<br/>
            그러나 오래된 영수증 사진을 다시 확인하면 추억이 다시 회상되며<br/>
            선명해지지만 그때 그 감정과는 색다른 의미로 다가옵니다.<br/>
            영수증사진기®로 지금 우리가 머물고 있는 자리를<br/>
            추억 한 켠에 새겨보세요.
          </p>
        </section>
        <div className='image-box'>
          <img src={process.env.PUBLIC_URL + '/images/09_01.jpg'} alt='영수증 사진' />
        </div>
      </div>

      {/* SECTION 09 MOBILE */}
      <div id='section09-mo' className='mo-only'>
        <section className='content-box'>
          <h3>
            <span>지금,&nbsp;</span>
            <span>
              우리가 머물고 있는<br/>
              자리를 추억 한 켠에<br/>
              새겨보세요
            </span>
          </h3>
          <p className='pc-only'>
            새로운 기억이 생기면서 오래된 기억은 빛이 바랜 것처럼 흐릿해집니다.<br/>
            그러나 오래된 영수증 사진을 다시 확인하면 추억이 다시 회상되며 선명해지지만<br/>
            그때 그 감정과는 색다른 의미로 다가옵니다.<br/>
            영수증사진기®로 지금 우리가 머물고 있는 자리를 추억 한 켠에 새겨보세요.
          </p>
          <p className='mo-only'>
            새로운 기억이 생기면서 오래된 기억은 빛이 바랜 것처럼 흐릿해집니다.<br/>
            그러나 오래된 영수증 사진을 다시 확인하면 추억이 다시 회상되며<br/>
            선명해지지만 그때 그 감정과는 색다른 의미로 다가옵니다.<br/>
            영수증사진기®로 지금 우리가 머물고 있는 자리를<br/>
            추억 한 켠에 새겨보세요.
          </p>
        </section>
        <div className='image-box'>
          <img src={process.env.PUBLIC_URL + '/images/09_01.jpg'} alt='영수증 사진' />
        </div>
      </div>
    </>
  )
}

export default Section09;