import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ReactComponent as Brief } from '../../images/brief_transparent.svg';

// 브리프 애월 02
function Section06() {
  gsap.registerPlugin(ScrollTrigger);

  const [update, setUpdate] = useState(0);

  // SECTION 06 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section06_ani01 = gsap.timeline();
      const section06_ani02 = gsap.timeline();

      section06_ani01
        .add(() => {
          document.querySelector('#section06-pc .logo').classList.add('animation');
        })

      section06_ani02
        .from('#section06-pc .particle01', { autoAlpha: 0, duration: .5, scale: .2, ease: 'back.out(3)' })
        .from('#section06-pc .content02 h2', { autoAlpha: 0, duration: .5, })
        .from('#section06-pc .content02-item01', { autoAlpha: 0, duration: .4, x: -10, delay: .1, })
        .from('#section06-pc .content02-item02', { autoAlpha: 0, duration: .4, x: -10, })
        .from('#section06-pc .content02-item03', { autoAlpha: 0, duration: .4, x: -10, })

      ScrollTrigger.create({
        animation: section06_ani01,
        trigger: '#section06-pc',
        start: 'top 45%',
      })

      ScrollTrigger.create({
        animation: section06_ani02,
        trigger: '#section06-pc .contents',
        start: 'top 65%',
      })

      ScrollTrigger.create({
        trigger: '#section06-pc .background-box',
        start: 'top top',
        pin: true,
        pinSpacing: false
      });
    })

    return () => context.revert();
  }, [update])
  
  window.addEventListener('resize', () => {setUpdate(window.innerWidth)});

  return(
    <>
      {/* SECTION 06 DESKTOP */}
      <div id='section06-pc' className='pc-only'>
        <div className='background-box'>
          <picture>
            <source media='(max-width: 768px)' srcSet={process.env.PUBLIC_URL + '/images/06_background_mo.webp'} type='image/webp' />
            <source srcSet={process.env.PUBLIC_URL + '/images/06_background.webp'} type='image/webp' />
            <img className='background-image' src={process.env.PUBLIC_URL + '/images/06_background.jpg'} alt='제주도 바다 이미지' />
          </picture>
        </div>
        <div className='contents'>
          <div className='logo-box'>
            <Brief className='logo'/>
          </div>
          <div className='content01'>
            <div className='particle01'></div>
            <picture>
              <source media='(max-width: 768px)' srcSet={process.env.PUBLIC_URL + '/images/06_briefcafe_mo.webp'} type='image/webp' />
              <source srcSet={process.env.PUBLIC_URL + '/images/06_briefcafe.webp'} type='image/webp' />
              <img className='content-image' src={process.env.PUBLIC_URL + '/images/06_briefcafe.jpg'} alt='브리프 카페 이미지' />
            </picture>
          </div>
          <section className='content02'>
            <h2>브리프 애월</h2>
            <p className='content02-item01'>
              우리는 꺼내어 보기 힘든 먼지 가득한 셀로판의 기록과 언제 꺼내어 보아도<br/>
              이상하지 않은 유한한 숫자들의 기록 그사이 어디쯤 존재한다.
            </p>
            <p className='content02-item02'>
            과거와 마주하는 듯한 무색(colorlessness)의 기록물을 무심코 접하게 되는 순간.<br/>
            우리는 그 순간의 순간으로 공간을 채워 또 다른 경험을 선사한다.
            </p>
            <p className='content02-item03'>
              우리들이 풀어내는 또 다른 차원의 경험이란 희미한 아날로그의 추억과<br/>
              또렷한 디지털의 기억이 공존하며 만들어지는 관계에서 오는 피로감,<br/>
              즉 ‘필연적 감정 소비’를 즉각적(instant)인 결과물(기억) 배출로 해소하는 것이다.
            </p>
          </section>
        </div>
      </div>

      {/* SECTION 06 MOBILE */}
      <div id='section06-mo' className='mo-only'>
        <div className='background-box'>
          <picture>
            <source media='(max-width: 768px)' srcSet={process.env.PUBLIC_URL + '/images/06_background_mo.webp'} type='image/webp' />
            <source srcSet={process.env.PUBLIC_URL + '/images/06_background.webp'} type='image/webp' />
            <img className='background-image' src={process.env.PUBLIC_URL + '/images/06_background.jpg'} alt='제주도 바다 이미지' />
          </picture>
        </div>
        <div className='contents'>
          <div className='logo-box'>
            <Brief className='logo'/>
          </div>
          <div className='content01'>
            <picture>
              <source media='(max-width: 768px)' srcSet={process.env.PUBLIC_URL + '/images/06_briefcafe_mo.webp'} type='image/webp' />
              <source srcSet={process.env.PUBLIC_URL + '/images/06_briefcafe.webp'} type='image/webp' />
              <img className='content-image' src={process.env.PUBLIC_URL + '/images/06_briefcafe.jpg'} alt='브리프 카페 이미지' />
            </picture>
          </div>
          <section className='content02'>
            <h2>브리프 애월</h2>
            <p className='content02-item01'>
              우리는 꺼내어 보기 힘든 먼지<br/>
              가득한 셀로판의 기록과 언제<br/>
              꺼내어 보아도 이상하지 않은<br/>
              유한한 숫자들의 기록 그사이<br/>
              어디쯤 존재한다.
            </p>
            <p className='content02-item02'>
            과거와 마주하는 듯한<br/>
            무색(colorlessness)의 기록물을 무심코<br/>
            접하게 되는 순간. 우리는 그 순간의 순간으로<br/>
            공간을 채워 또 다른 경험을 선사한다.
            </p>
            <p className='content02-item03'>
              우리들이 풀어내는 또 다른 차원의 경험이란 희미한<br/>
              아날로그의 추억과 또렷한 디지털의 기억이 공존하며<br/>
              만들어지는 관계에서 오는 피로감, 즉 ‘필연적 감정 소비’를<br/>
              즉각적(instant)인 결과물(기억) 배출로 해소하는 것이다.
            </p>
          </section>
        </div>
      </div>
    </>
  )
}

export default Section06;