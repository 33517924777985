import React from 'react';

import Progress from '../components/ScrollProgress.js';
import Header from '../components/Header.js';
import SECTION01_PC from '../components/main/01_Section01_PC.js';
import SECTION01_MO from '../components/main/01_Section01_MO.js';
// import SECTION02_PC from '../components/main/02_Section02_PC.js';
// import SECTION02_MO from '../components/main/02_Section02_MO.js';
import Section03 from '../components/main/03_Section03.js';
import Section04 from '../components/main/04_Section04.js';
import Section05 from '../components/main/05_Section05.js';
import Section06 from '../components/main/06_Section06.js';
import Section07 from '../components/main/07_Section07.js';
import SECTION08_PC from '../components/main/08_Section08_PC.js';
import SECTION08_MO from '../components/main/08_Section08_MO.js';
import Section09 from '../components/main/09_Section09.js';
import Section10 from '../components/main/10_Section10.js';
import SECTION11_PC from '../components/main/11_Section11_PC.js';
import SECTION11_MO from '../components/main/11_Section11_MO.js';
import Section12 from '../components/main/12_Section12.js';
import Section13 from '../components/main/13_Section13.js';
import Section14 from '../components/main/14_Section14.js';
import Footer from '../components/Footer.js'
import TopButton from '../components/TopButton.js';

import '../styles/Main.css';
import 'swiper/css';

const Main = () => {
  return (
    <>
      <Progress />
      <Header />

      {/* brief (________) moment */}
      <SECTION01_PC />
      <SECTION01_MO />

      {/* brief moment 텍스트 모션 */}
      {/* <SECTION02_PC /> */}
      {/* <SECTION02_MO /> */}

      {/* 브리프 애월 01 */}
      <Section03 />

      {/* MD상품 판매 */}
      <Section04 />

      {/* 영수증사진기 */}
      <Section05 />

      {/* 브리프 애월 02 */}
      <Section06 />

      {/* 기억의 단서, 기억과 연결되어 있는, 사진의 가치 */}
      <Section07 />

      {/* 기억의 영수증 */}
      <SECTION08_PC />
      <SECTION08_MO />

      {/* 그 때의 우리는_____ */}
      <Section09 />

      {/* 브리프 포토 플로우 */}
      <Section10 />

      {/* Brief Crew (2D) */}
      <SECTION11_PC />
      <SECTION11_MO />

      {/* Brief Crew (3D) */}
      <Section12 />

      {/* 브리프 애월 입점 MD */}
      <Section13 />

      {/* Contact Us */}
      <Section14 />
      
      <Footer />
      <TopButton />

      <div className='center-line'></div>
    </>
  );
}

export default Main;
