import React from 'react';
import { useSwiper } from 'swiper/react';

function Navigation() {
  const swiper = useSwiper();

  function slidePrev(event) {
    // Section05 Swiper Function
    if (event.target.parentElement.parentElement.classList.contains('section05_content01')) {
      var target = document.querySelector('#section05 .section05_swiper .swiper-wrapper .swiper-slide-next').previousElementSibling;
      target.classList.add('swiper-slide-down');
      setTimeout(() => {
        target.classList.remove('swiper-slide-down');
      }, 200)
    }

    swiper.slidePrev();
  }

  return(
    <div className='swiper-navigation pc-only'>
      <button className='swiper-navigation-prev' onClick={(event) => slidePrev(event)}><span>Prev</span></button>
      <button className='swiper-navigation-next' onClick={() => swiper.slideNext()}><span>Next</span></button>
    </div>
  )
}

export default Navigation;