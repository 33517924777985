import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { ReactComponent as Brief } from '../../images/brief_transparent.svg';

// 브리프 포토 플로우
function Section10() {
  const [update, setUpdate] = useState(0);

  gsap.registerPlugin(ScrollTrigger);

  // SECTION 10 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section10_ani = gsap.timeline();

      section10_ani
        .to('body', { duration: .05 })
        .add('01')
        .to('#section10 .image-container .image-box01', { xPercent: -470, duration: 3, }, '01')
        .to('#section10 .image-container .image-box02', { xPercent: -470, duration: 3, }, '01')
        .to('#section10 .image-container .image-box03', { xPercent: -470, duration: 3, }, '01')
        .to('#section10 .image-container .image-box04', { xPercent: -470, duration: 3, }, '01')
        .to('#section10 .content-container .logo-box', { delay: .2, top: '18.6%', left: '7.4%', width: '30.81%', transform: 'translate(0, 0)', ease:'power3.out', duration: .77, })
        .from('#section10 .content-container .content', { delay: .2, autoAlpha: 0, x: -20, duration: .5, })
        .to('body', { duration: 1 })
      
      ScrollTrigger.create({
        animation: section10_ani,
        trigger: '#section10',
        start: 'top top',
        end: '+=7300',
        scrub: 5,
        pin: true,
        anticipatePin: 1
      });
    });
    return () => context.revert();
  })

  window.addEventListener('resize', () => {
    setUpdate(window.innerWidth);
  })

  return(
    <div id='section10' className='pc-only'>
      <div className='content-container'>
        <div className='logo-box'>
          <Brief className='logo' alt='브리프 카페 로고' />
        </div>
        <p className='content'>
          과거와 현재를 연결해주는 매개체, 브리프.<br/>
          어제는 오늘의 기억이 되고, 내일은 오늘의 꿈이 될 것입니다.<br/>
          새로운 경험들을 향해 나아가는 내일의 우리는 영수증사진기® 통해<br/>
          오늘을 추억할 수 있습니다.<br/>
          <br/>
          새로운 기억이 생기면서 오래된 기억은 빛이 바랜 것처럼 흐릿해집니다.<br/>
          그러나 오래된 영수증 사진을 다시 확인하면 추억이 다시 회상되며 선명해지지만<br/>
          그 때 찍었을 때의 감정과는 색다른 의미로 다가옵니다.<br/>
          영수증사진기®로 지금 우리가 머물고 있는 자리를 추억 한 켠에 새겨보세요.<br/>
          “ 그 때의 우리는 _______ “
        </p>
      </div>
      <div className='image-container' data={update}>
        <div className='image-box01'>
          <div className='image-wrapper'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/10_01.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/10_01.jpg'} className='image' alt='브리프 포토 플로우 이미지01' />
            </picture>
          </div>
        </div>
        <div className='image-box02'>
          <div className='image-wrapper'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/10_02.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/10_02.jpg'} className='image' alt='브리프 포토 플로우 이미지02' />
            </picture>
          </div>
        </div>
        <div className='image-box03'>
          <div className='image-wrapper'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/10_03.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/10_03.jpg'} className='image' alt='브리프 포토 플로우 이미지03' />
            </picture>
          </div>
        </div>
        <div className='image-box04'>
          <div className='image-wrapper'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/10_04.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/10_04.jpg'} className='image' alt='브리프 포토 플로우 이미지04' />
            </picture>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section10;