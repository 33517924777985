import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// BRIEF CREW (3D)
function Section12() {
  const [update, setUpdate] = useState(0);

  gsap.registerPlugin(ScrollTrigger);

  // SECTION 12 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      ScrollTrigger.create({
        trigger: '#section12-pc .group-image',
        start: 'top top',
        pin: true,
        pinSpacing: false
      });
    })
    return () => context.revert();
  })

  window.addEventListener('resize', () => {
    setUpdate(window.innerWidth);
  })

  return(
    <>
      {/* SECTION 12 DESKTOP */}
      <div id='section12-pc' className='pc-only'>
        <div className='group-container' data={update}>
          <picture className='group-image'>
            <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/group_pc.webp'} type='image/webp' />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/group_pc.jpg'} alt={'브리프 크루 3D 이미지'} />
          </picture>
        </div>
        <div className='char-container'>
          <div className='char-box-01'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/tanky_camera.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/tanky_camera.png'} className='char-image tanky01' alt={'탱키 3D & 영수증사진기 10인치'} />
            </picture>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/aiden_camera.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/aiden_camera.png'} className='char-image aiden01' alt={'에이든 3D & 영수증사진기 8인치'} />
            </picture>
          </div>
          <div className='char-box-02'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/awory_camera.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/awory_camera.png'} className='char-image awory01' alt={'애워리 3D & 영수증사진기 8인치 벽걸이형'} />
            </picture>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/lite_camera.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/lite_camera.png'} className='char-image lite01' alt={'리트 3D & 영수증사진기 LITE'} />
            </picture>
          </div>
          <div className='char-box-03'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/tanky_origin.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/tanky_origin.png'} className='char-image tanky02' alt={'탱키 3D & 영수증사진기 10인치'} />
            </picture>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/awory_origin.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/awory_origin.png'} className='char-image awory02' alt={'에이든 3D & 영수증사진기 8인치'} />
            </picture>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/lite_origin.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/lite_origin.png'} className='char-image lite02' alt={'탱키 3D & 영수증사진기 10인치'} />
            </picture>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/aiden_origin.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/aiden_origin.png'} className='char-image aiden02' alt={'에이든 3D & 영수증사진기 8인치'} />
            </picture>
          </div>
        </div>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/brief_crew.svg'} className='name-image' alt={'브리프 크루'} />
      </div>

      {/* SECTION 12 MOBILE */}
      <div id='section12-mo' className='mo-only'>
        <div className='group-container'>
          <picture className='group-image'>
            <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/group_mo.webp'} type='image/webp' />
            <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/group_mo.jpg'} alt={'브리프 크루 3D 이미지01'} />
          </picture>
        </div>
        <div className='char-container'>
          <div className='char-box-01'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/gather_camera.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/gather_camera.png'} alt={'브리프 크루 캐릭터 & 영수증사진기'} />
            </picture>
          </div>
          <div className='char-box-02'>
            <picture>
              <source srcSet={process.env.PUBLIC_URL + '/images/brief_crew/3D/gather_origin.webp'} type='image/webp' />
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/gather_origin.png'} alt={'브리프 크루 3D 이미지02'} />
            </picture>
          </div>
        </div>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/3D/brief_crew.svg'} className='name-image' alt={'브리프 크루'} />
      </div>
    </>
  )
}

export default Section12;