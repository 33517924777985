import React, { useEffect } from 'react';

const TopButton = () => {
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 400) {
                document.getElementById('topButton').style.display = 'block';
            } else {
                document.getElementById('topButton').style.display = 'none';
            }
        })
    })

    const handleScroll = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    }

    return (
        <button type='button' id='topButton' onClick={handleScroll}></button>
    )
}

export default TopButton;