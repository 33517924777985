function Header() {
  return(
    <div id='header'>
      <div className='container'>
        <h1>카페.브리프: cafe.brief</h1>
        <img src={process.env.PUBLIC_URL + '/images/brief_black.svg'} className='logo' alt={'브리프 로고'} />
      </div>
    </div>
  )
}

export default Header;