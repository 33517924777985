import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Swiper, SwiperSlide } from 'swiper/react';

import Navigation from '../SwiperNavigation';

// 영수증사진기
function Section05() {
  gsap.registerPlugin(ScrollTrigger);

  const [update, setUpdate] = useState(0);

  // SECTION 05 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      let matchMedia = gsap.matchMedia();

      matchMedia.add("(min-width: 1025px)", () => {
        const section05_ani = gsap.timeline();

        section05_ani
          .from('#section05 .particle01', { autoAlpha: 0, duration: .3, scale: .2, ease: 'back.out(3)' })
          .from('#section05 .particle02', { autoAlpha: 0, duration: .3, scale: .2, ease: 'back.out(3)' })
          .from('#section05 .particle03', { autoAlpha: 0, duration: .3, scale: .2, ease: 'back.out(3)' })
          .from('#section05 .particle04', { autoAlpha: 0, duration: .5, y: 100, ease: 'back.out(3)', delay: .2 })

        ScrollTrigger.create({
          animation: section05_ani,
          trigger: '#section05',
          start: 'top 60%',
        })
      })

      matchMedia.add("(max-width: 1024px)", () => {
        const section05_ani = gsap.timeline();

        section05_ani
          .from('#section05 .particle01', { autoAlpha: 0, duration: .3, scale: .2, ease: 'back.out(3)' })
          .from('#section05 .particle04', { autoAlpha: 0, duration: .5, y: 100, ease: 'back.out(3)', delay: .2 })

        ScrollTrigger.create({
          animation: section05_ani,
          trigger: '#section05',
          start: 'top 60%',
        })
      })
    })

    return () => context.revert();
  }, [update])

  window.addEventListener('resize', () => {setUpdate(window.innerWidth)});

  const slideList = [
    {
      id: '01',
      image: '/images/receipt_camera/01.jpg',
    },
    {
      id: '02',
      image: '/images/receipt_camera/02.jpg',
    },
    {
      id: '03',
      image: '/images/receipt_camera/03.jpg',
    },
    {
      id: '04',
      image: '/images/receipt_camera/04.jpg',
    },
    {
      id: '05',
      image: '/images/receipt_camera/05.jpg',
    },
    {
      id: '06',
      image: '/images/receipt_camera/06.jpg',
    },
    {
      id: '07',
      image: '/images/receipt_camera/01.jpg',
    },
    {
      id: '08',
      image: '/images/receipt_camera/02.jpg',
    },
    {
      id: '09',
      image: '/images/receipt_camera/03.jpg',
    },
    {
      id: '10',
      image: '/images/receipt_camera/04.jpg',
    },
    {
      id: '11',
      image: '/images/receipt_camera/05.jpg',
    },
    {
      id: '12',
      image: '/images/receipt_camera/06.jpg',
    }
  ]

  return(
    <div id='section05'>
      <div className='particle01'></div>
      <div className='particle02 pc-only'></div>
      <div className='particle03 pc-only'></div>
      <div className='particle04'></div>
      <div className='particle05'></div>
      <Swiper className='swiper' slidesPerView={'auto'} loop={true}>
        <section className='contents'>
          <div className='content01'>
            <Navigation />
          </div>
          <div className='content02'>
            <h2>영수증사진기®</h2>
            <p>
              브리프에서 직접 제작하여 판매하고 있는 영수증사진기®는<br/>
              상표와 디자인, 특허 등록(한국, 일본)이 완료된 제품으로<br/>
              저희 브리프에서 독점적 판매 권한을 가지고 있습니다.<br/>
            </p>
          </div>
        </section>
        {slideList.map((slide) => {
          return(
            <SwiperSlide key={slide.id}>
              <img src={process.env.PUBLIC_URL + `${slide.image}`} alt={`MD상품 판매 이미지${slide.id}`} />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default Section05;