import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// BRIEF CREW (2D)
function Section11_MO() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 11 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section11_ani_mo = gsap.timeline();

      section11_ani_mo
        .from('#section11-mo .story-image', { autoAlpha: 0, duration: .6, y:80, ease: 'back.out(3)' })

      ScrollTrigger.create({
        animation: section11_ani_mo,
        trigger: '#section11-mo',
        start: 'top 55%',
      })
    })

    return () => context.revert();
  })

  // INTRO FUNCTION
  useEffect(() => {
    const section11_mo = document.querySelector('#section11-mo');
    const els_char = section11_mo.querySelectorAll('.intro-char-image');
    const el_container = section11_mo.querySelector('.modal-container');
    const els_item = section11_mo.querySelectorAll('.modal-item');
    const els_close = section11_mo.querySelectorAll('.modal-close-btn');

    els_char.forEach((char, i) => {
      char.addEventListener('click', () => {
        el_container.classList.remove('displaynone');
        els_item[i].classList.remove('displaynone');
      })
    })

    els_close.forEach((close) => {
      close.addEventListener('click', (event) => {
        el_container.classList.add('displaynone');
        els_item.forEach((item) => {
          item.classList.add('displaynone');
        })
        event.preventDefault();
      })
    })
  })

  const charList = [
    {
      'name_en': 'tanky',
      'name_ko': '탱키',
      'tags': ['친근한', '핵인싸', '장난꾸러기', '나잡아봐라'],
      'contents': [
        '안녕하세요! 나는 탱키에요.',
        '다른 친구들과는 다르게 나는 라벨스티커를 출력한답니다!',
        '나를 찍을 땐 조심해용.',
        '나는 라벨스티커가 출력될 때 호다닥 도망가는 걸 좋아하거든요.',
        '스티커 사진이 갖고 싶어용? 그렇다면?',
        '나 잡아봐라~!'
      ]
    },
    {
      'name_en': 'awory',
      'name_ko': '애워리',
      'tags': ['유쾌한', '다정한', '능글맞은', '윙크달인'],
      'contents': [
        '안녕하세요 ;-) 저는 애워리라고 해요.',
        '벽 뒤에 숨은 저를 찾아보세요!',
        '여러분을 향해 몰래 윙크하는 저를 발견하신다면',
        '소중한 추억을 영수증사진에 담아드릴게요.',
        '여러분은 여기 브리프 애월에서 어떤 감정을',
        '오래오래 기억하고 싶으신가요?'
      ]
    },
    {
      'name_en': 'lite',
      'name_ko': '리트',
      'tags': ['귀여운', '애교쟁이', '사람좋아', '나랑놀아'],
      'contents': [
        '안녕? 난 리트야.',
        '나는 사람을 너무너무 좋아해!',
        '그러니까 브리프 애월에 오면 꼭 나를 만나고 가야 해?',
        '나랑 같이 놀아주면 나는 그 대신에 즐거운 추억을 선물해 줄게.',
        '작다고 무시하면 안돼! 비록 작지만,',
        '다른 크루원들처럼 소중하고 오래오래 남는 기억을 담을 수 있거든!'
      ]
    },
    {
      'name_en': 'aiden',
      'name_ko': '에이든',
      'tags': ['샤이한', '여행객', '자유영혼', '내향관종'],
      'contents': [
        '안녕하세요! 나는 에이든이에요.',
        '한가지 미리 소개할게요, 나는 부끄럼이 많아요.',
        '그렇지만 내게 와서 사진을 찍는다면',
        '한쪽 눈을 감고 성심성의껏 찍어드릴게요.',
        '사진을 가장 잘 찍는 방법은 애정을 듬뿍 담아 찍는 거라는 것,',
        '알고 계시죠?'
      ]
    }
  ]

  return(
    <div id='section11-mo' className='mo-only'>
      <strong className='title'>HELLO!<br/>BRIEF CREW</strong>
      <div className='story-container'>
        <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/story/group.svg'} className='story-image' alt={'브리프 크루 스토리 이미지'} />
        <div className='story-box'>
          <strong className='story-title'>BACKGROUND STORY</strong>
          <p className='story-content'>
            브리프에는 네 명의 친구들이 있어요.<br/>
            그 친구들이 궁금하다면,<br/>
            불이 꺼지고 난 뒤에 영수증사진기®를 지켜보면 돼요.<br/>
            그럼 네 개의 그림자가 사람들의 눈을 피해<br/>
            움직이는 것을 알아챌 수 있을 거예요.<br/>
            <br/>
            우리는 무리지어 다니는 이 친구들을<br/>
            브리프 크루라고 부르기로 했답니다.<br/>
            브리프 크루는 여기저기 여행을 다니며 느낀 감정들을 바탕으로,<br/>
            영수증사진기®로 돌아와 사진을 찍어주며 사람들의 하루를 기록해줘요.<br/>
            이 친구들은 주로 서울 연희동과 제주 애월에서 포착된다고 하는데…<br/>
            <br/>
            혹시, 어떤 친구들인지 궁금하지 않나요?<br/>
            앞으로 브리프 크루의 여정을 함께 지켜봐 주세요!
          </p>
        </div>
      </div>
      <div className='intro-container'>
        <ul className='intro-title-container'>
          <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/brief_crew.svg'} className='intro-brief-crew' alt={'브리프 크루'} />
          <span className='intro-title-content'>
            브리프 크루를 소개합니다
            <br/>
            친구들을 터치해 보세요!
          </span>
        </ul>
        <ul className='intro-char-container'>
          <li className='intro-char-box'>
            <div className='intro-char-wrapper'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/tanky_default+shadow.svg'} className='intro-char-image tanky' alt={'탱키 소개 이미지01'} />
            </div>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-wrapper'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/awory_default+shadow.svg'} className='intro-char-image awory' alt={'애워리 소개 이미지01'} />
            </div>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-wrapper'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/lite_default+shadow.svg'} className='intro-char-image lite' alt={'리트 소개 이미지01'} />
            </div>
          </li>
          <li className='intro-char-box'>
            <div className='intro-char-wrapper'>
              <img src={process.env.PUBLIC_URL + '/images/brief_crew/2D/intro/aiden_default+shadow.svg'} className='intro-char-image aiden' alt={'에이든 소개 이미지01'} />
            </div>
          </li>
        </ul>
        <ul className='modal-container displaynone'>
          {charList.map((char) => {
            return(
              <li className='modal-item displaynone' key={Math.random()}>
                <div className='modal-char-box'>
                  <img src={process.env.PUBLIC_URL + `/images/brief_crew/2D/intro/${char.name_en}_active.svg`} className={`modal-char-image ${char.name_en}`} alt={`${char.name_ko} 소개 이미지02`} />
                </div>
                <div className='modal-name-box'>
                  <span className='modal-name-en'>{`${char.name_en.toUpperCase()}`}</span>
                  <span className='modal-name-ko'>{`${char.name_ko}`}</span>
                </div>
                <ul className='modal-tag-box'>
                  {char.tags.map((tag) => {
                    return(
                      <li className='modal-tag' key={Math.random()}>{`#${tag}`}</li>
                    )
                  })}
                </ul>
                <p className='modal-content'>
                  {char.contents.map((content, i) => {
                    if (i === char.contents.length - 1) return <span key={Math.random()}>{content}</span>;
                    else return (<span key={Math.random()}>{content}<br/></span>);
                  })}
                </p>
                <button href='#' className='modal-close-btn'></button>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Section11_MO;