import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// brief (________) moment
function Section01_PC() {
  gsap.registerPlugin(ScrollTrigger);

  // SECTION 01 ANIMATION
  useEffect(() => {
    const context = gsap.context(() => {
      const section01_pc_ani = gsap.timeline();

      section01_pc_ani
        .from('#section01-pc .image01', {delay: 2, autoAlpha: 0, duration: 2, y: '2.0833vw'})
        .add('start01')
        .to('#section01-pc .image01', {delay: 1.5, autoAlpha: 0, duration: 1.5, y: '-2.0833vw'}, 'start01')
        .from('#section01-pc .image02', {delay: 1.5, autoAlpha: 0, duration: 2, y: '2.0833vw'}, 'start01')
        .add('start02')
        .to('#section01-pc .image02', {delay: 1.5, autoAlpha: 0, duration: 1.5, y: '-2.0833vw'}, 'start02')
        .from('#section01-pc .image03', {delay: 1.5, autoAlpha: 0, duration: 2, y: '2.0833vw'}, 'start02')
        .to('body', { duration: 4 })
    
      ScrollTrigger.create({
        animation: section01_pc_ani,
        trigger: '#section01-pc',
        start: 'top top',
        end: '+=2800',
        scrub: 2.5,
        pin: true,
        anticipatePin: 1,
      });
    });

    return () => context.revert();
  })

  return(
    <div id='section01-pc' className='pc-only'>
      <div className='background'>
        <picture>
          <source srcSet={process.env.PUBLIC_URL + '/images/01_background.webp'} type='image/webp' />
          <img className='background-image' src={process.env.PUBLIC_URL + '/images/01_background.png'} alt='브리프 카페 이미지' />
        </picture>
      </div>
      <div className='contents'>
        <div className='content01'>
          <div className='text-box'>
            brief
            <div className='blank-box'>
              (________________)
              <picture className='image01'>
                <source srcSet={process.env.PUBLIC_URL + '/images/01_orange.webp'} type='image/webp' />
                <img src={process.env.PUBLIC_URL + '/images/01_orange.png'} alt='감귤 이미지' />
              </picture>
              <picture className='image02'>
                <source srcSet={process.env.PUBLIC_URL + 'images/01_coffee.webp'} type='image/webp' />
                <img src={process.env.PUBLIC_URL + '/images/01_coffee.png'} alt='커피 이미지' />
              </picture>
              <picture className='image03'>
                <source srcSet={process.env.PUBLIC_URL + 'images/01_flower.webp'} type='image/webp' /> 
                <img src={process.env.PUBLIC_URL + '/images/01_flower.png'} alt='동백꽃 이미지' />
              </picture>
            </div>
            moment
          </div>
        </div>
        <p className='content02'>
          <span>
            ‘나’는 무엇을 좋아하나요?<br/>
            세상 가득한 수많은 취향 가운데 어느 것을 사랑하나요?<br/>
            선택이 어려워도 괜찮아요. 이곳, brief에서 당신의 취향을 찾아보세요.
          </span>
          <span>
            brief에는 각양각색 브랜드가 가득 차 있어요.<br/>
            하나의 특색이나 다채로움으로 당신의 취향을 저격할 수도<br/>
            혹은 새로운 취향을 만나게 해 줄 수도 있을 거예요.
          </span>
          <span>
            ‘나’의 마음을 따듯하게 채우고 그 마음을 소중한 이에게 전하세요.<br/>
            여기에, 잠시 머물다 가세요.<br/>
            a brief stay for you. brief moment
          </span>
        </p>
      </div>
    </div>
  )
};

export default Section01_PC;