function Footer() {
  return(
    <div id='footer'>
      <div className="logo-container pc-only">
        <img src={process.env.PUBLIC_URL + '/images/brief_black.svg'} className='logo' alt={'브리프 로고'} />
      </div>
      <div className="content-container">
        <div className="content-box-01">
          <p className="company-info">
            <span>주식회사 캐롯닷밀</span>
            <span>제주특별자치도 제주도 애월읍 광성로 76</span>
          </p>
          <p className="company-info">
            <span>T. 064 711 5507  |  F. 064 711</span>
            <span>Business No. 733 85 00714</span>
          </p>
        </div>
        <div className="content-box-02">
          <span>Copyright © 2018 (주)캐롯닷밀 ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </div>
  )
}

export default Footer;